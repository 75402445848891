<template>
  <div>
    <c-card title="화학물질 관리대장 엑셀업로드" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="LBLAPPLY" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'chemEmissionsSurveyTargetExcelUpload',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
        }
      },
    },
  },
  data() {
    return {
      excelUploadInfo: {
        rowKeys: ['chmInoutManageId'], // 데이터들의 키 속성값
        taskClassCd: 'CHM_INOUT', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            name: 'materialCd',
            label: '자재코드',
            required: true,
            style: 'width:150px',
            align: 'center',
            type: 'chemMaterial',
          },
          {
            name: 'yearmonth',
            label: '연월',
            required: true,
            style: 'width:80px',
            align: 'center',
          },
          {
            name: 'inoutDate',
            label: '전기일자',
            required: true,
            style: 'width:100px',
            align: 'center',
            type: 'date'
          },
          {
            name: 'inVendorName',
            label: '입고처',
            style: 'width:150px',
            align: 'left',
          },
          {
            name: 'inAmount',
            label: '입고수량',
            style: 'width:100px',
            align: 'right',
          },
          {
            name: 'materialName',
            label: '자재명',
            style: 'width:250px',
            align: 'left',
          },
          {
            name: 'remarks',
            label: '제조구분',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'outAmount',
            label: '출고수량',
            style: 'width:100px',
            align: 'right',
          },
          {
            name: 'carryover',
            label: '이월수량',
            required: true,
            style: 'width:100px',
            align: 'right',
          },
          {
            name: 'inventory',
            label: '재고수량',
            required: true,
            style: 'width:100px',
            align: 'right',
          },
        ]],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          let message = '적용하시겠습니까?\n(※ 기존 데이터를 지운 후 업로드됩니다.)'
          if (errorDataCheck) {
            message = '에러가 있는 데이터가 존재합니다.\n에러 있는 데이터를 제외한 나머지 데이터를 적용하시겠습니까?\n(※ 기존 데이터를 지운 후 업로드됩니다.)'
          }
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$emit('closePopup', this.excelUploadInfo.data[0]);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      }
    }
  }
};
</script>
